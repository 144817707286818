// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MODAL */

.ant-modal-close {
  outline: none !important;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: $kronnika-pink;
}
.ant-modal .ant-modal-header {
  padding-bottom: 1em;
  border-bottom: 1px solid $gray-3;
}
.ant-modal .ant-modal-footer {
  padding-top: 1em;
  border-top: 1px solid $gray-3;
}
